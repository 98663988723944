<template>
  <div>
    <CommonPop style="background-color: #fff; height: 100%; width: 100%; max-width: 100%; padding:0"
      overlayBgColor="#14151e" position="bottom" @close='closePop' v-model="show" @click-overlay="closePop">

      <div class="upload-wrap">
        <div class="title">{{ $t('gender.uploadVideo') }}</div>

        <VanUploader ref="uploader" v-if="uploadStatus === 0 || uploadStatus === 3" :after-read="afterRead" :max-count="1"
          accept="video/*" capture="user">
          <div class="upload">
            <img src="@/assets/img/user/upload-center.png" />
          </div>
        </VanUploader>

        <div class="loadingBox" v-show="uploadStatus === 1">
          <van-loading v-if="uploadStatus === 1" class="loadingBox" size="40px" color="#FFF" vertical>上传中...</van-loading>
        </div>


        <div class="word" v-if="uploadStatus !== 2">
          <p>{{ $t('gender.uploadVideoTip') }} </p><br /><br /><br /><br />
          <p>请在录制时复述以下数字： </p><br />
          <p style="color:#fd2084; font-size: 40px; font-weight: bold;">{{ randomNum }}</p>
          <p style="color:#777777; font-size: 20px;margin-top: 30px;line-height: 1.2;">注意提示：没有完成视频认证的用户，无法在首页展示</p>
        </div>

        <div class="result" :class="{ 'upload-failed': uploadStatus === 3 }">
          <div class="success" v-if="uploadStatus === 2">
            <img src="@/assets/img/user/upload-success.png" />
            <div class="tip">视频上传成功</div>
            <div class="tip" style="color: #524774;font-weight: bold;">待审核</div>
          </div>

          <div class="failed" v-if="uploadStatus === 3">
            <img src="@/assets/img/user/upload-failed.png" />
            <div class="tip">网络异常请重试</div>
          </div>
        </div>

        <div class="countdownBox" v-show="uploadStatus === 2">
          <van-count-down ref="countDown" seconds :time="3000" :auto-start="false" format="ss 秒后跳转首页..."
            @finish="finish" />
        </div>

        <!--      <div class="button-line"></div>-->
      </div>

    </CommonPop>
    <PersonTipPop v-model="showPersonTipPop"/>
  </div>
</template>


<script>
import CommonPop from '../../components/CommonPop.vue';
import { uploadFile } from '@/service/upload'
import { reportVideoPath } from '@/service/user'
import { mapActions, mapState } from "vuex";
import $gbUtils from "../../utils/gbUtils";
import PersonTipPop from "../../components/PersonTipPop.vue";

window.onload = function () {
  // 阻止双击放大
  let lastTouchEnd = 0
  document.addEventListener('touchstart', function (event) {
    if (event.touches.length > 1) { event.preventDefault() }
  })
  document.addEventListener('touchend', function (event) {
    let now = (new Date()).getTime()
    if (now - lastTouchEnd <= 300) { event.preventDefault() }
    lastTouchEnd = now
  }, false)

  // 阻止双指放大
  document.addEventListener('gesturestart', function (event) {
    event.preventDefault()
  })
}



export default {
  name: 'SelectGender',
  components: {
    CommonPop,
    PersonTipPop
  },
  data() {
    return {
      show: true,
      loading: false,
      showConfirmPop: false,
      uploadStatus: 0, // 0:未上传 1:上传中 2:上传成功 3:上传失败
      randomNum: null
    }
  },
  computed: {
    $gbUtils() {
      return $gbUtils
    },
    ...mapState(['userInfo','showPersonTipPop'])
  },
  methods: {
    ...mapActions(['changeUserInfo','changeShowPersonTipPop']),
    init() {
      this.randomNum = this.$gbUtils.genRandomNum()
    },
    closePop() {
      this.$emit('close')
    },
    // 上传之前
    async afterRead(files) {
      console.log('file', files.file)
      this.uploadStatus = 1
      // 上传认证视频
      const resp = await uploadFile(files.file, 'video')
      if (resp.success) {
        const params = {
          video: resp.s3Key,
          code: this.randomNum
        }
        // 上报视频路径
        const res = await reportVideoPath(params)
        if (res.code === 200) {
          this.userInfo.status = 20 // 已上传
          await this.changeUserInfo(this.userInfo)

          this.changeShowPersonTipPop(true)
          this.uploadStatus = 2
          this.$refs.countDown.start()
        } else {
          console.log('上传视频失败')
          this.uploadStatus = 3
        }
      } else {
        console.log('上传视频失败')
        this.uploadStatus = 3
      }
    },
    // 倒计时结束跳转首页
    finish() {
      this.changeShowPersonTipPop(false)
      this.$gbUtils.handleToPage('/home')
    }
  },
  mounted() {
    this.init()
  }
}
</script>

<style lang="scss" scoped>
.upload-wrap {
  width: 100%;
  height: 100%;
  padding: 50px 20px 20px 20px;
  background: #14151e;
  position: relative;

  .loadingBox {
    height: 130px;
    width: 130px;
    color: #524774;
    // border: 1px solid red;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    margin-left: calc(50% - 65px);
    margin-top: calc(50% - 65px);
  }

  .title {
    color: #fff;
    font-size: 20px;
    font-weight: bold;
    width: inherit;
    text-align: center;
    line-height: 28px;
  }

  /deep/.van-uploader {
    display: flex;
    justify-content: center;
    margin-top: calc(50% - 65px);
  }

  /deep/.van-uploader__wrapper {
    width: 130px;
    height: 130px;
  }

  /deep/.van-uploader__input-wrapper {
    width: 130px;
    height: 130px;
    position: relative;
  }

  /deep/.van-uploader__input {
    z-index: 99;
  }

  .upload {
    width: 130px;
    height: 130px;
    // margin-top: 20px;
    border-radius: 4px;
    margin-left: calc(50% - 65px);
    background-image: url('~@/assets/img/user/upload-border.png');
    background-size: 130px 130px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;

    img {
      width: 56px;
      height: 56px;
    }
  }

  .word {
    margin-top: 50px;
    text-align: center;
    font-size: 16px;
    font-weight: bold;
  }

  .result {
    width: 100px;
    height: 100px;
    /* margin-top: 50px; */
    display: flex;
    justify-content: center;
    align-items: center;
    align-items: center;
    position: absolute;
    left: calc(50% - 50px);
    top: calc(50% - 150px);

    .success,
    .failed {
      width: 100px;
      height: 100px;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;

      img {
        width: 60px;
        height: 60px;
      }

      .tip {
        width: 100%;
        margin-top: 20px;
        text-align: center;
        font-size: 16px;
        font-weight: bold;
      }
    }

  }

  .upload-failed {
    top: calc(50% + 70px);
  }

  .countdownBox {
    width: 200px;
    height: 20px;
    position: absolute;
    left: calc(50% - 100px);
    top: 50%;

    // border: 1px solid red;
    .van-count-down {
      color: #fff;
      font-size: 14px;
      text-align: center;
      font-weight: bold;
    }
  }



  .button-line {
    position: absolute;
    width: 134px;
    height: 5px;
    left: calc(50% - 134px/2);
    bottom: 8px;
    background: #FFFFFF;
    border-radius: 100px;
  }

  /deep/.van-button__text {
    font-size: 14px;
    font-weight: bold;
  }
}
</style>