<template>
  <VanPopup
    style="box-sizing: border-box;  width: 95%;  max-width: 400px;
      padding: 24px 0;
      color: #FFFFFF;
    "
    :overlayStyle	= "{ background: overlayBgColor}"
    :style="{ backgroundColor: bgColor }"
    :round="round"
    :closeable="closeable"
    @close='closePop'
    v-model="show"
    @click-overlay="closePop"
  >
    <div class="pop-title" v-if="popTitle">
      {{popTitle}}
    </div>
    <slot></slot>
  </VanPopup>
</template>

<script>
export default {
  name: '',
  /**
   * @closeIcon
   */
  props: {
    overlayBgColor: {
      type: String,
      default: 'rgba(123, 101, 128, 0.70)'
    },
    bgColor: {
      type: String,
      default: '#443847'
    },
    closeable: {
      type: Boolean,
      default: false
    },
    round: {
      type: Boolean,
      default: false
    },
    popTitle: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      show: true
    }
  },
  methods: {
    closePop () {
      this.$emit('close')
    }
  },
  mounted () {

  }
}
</script>

<style scoped lang="scss">
.pop-title {
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  padding-bottom: 10px;
  border-bottom: 2px dotted rgba(255, 255, 255, 0.20);
}
/deep/ .van-popup__close-icon {
  color: #fff;
  font-size: 24px;
  font-weight: bold;
}
</style>
